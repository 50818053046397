import React, { memo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Spinner = ({ className, color, size = '6rem', band = '5px', interval = '1.2s' }) => (
  <OuterContainer aria-live="polite" aria-label="Loading..." aria-busy="true">
    <SpinnerContainer className={className} color={color} size={size} band={band} interval={interval}>
      <div className="first"></div>
      <div className="second"></div>
      <div className="third"></div>
      <div className="fourth"></div>
    </SpinnerContainer>
  </OuterContainer>
);

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  band: PropTypes.string,
  interval: PropTypes.string,
};

const SpinnerContainer = styled('div')`
  display: inline-block;
  position: relative;
  width: ${(p) => p.size};
  height: ${(p) => p.size};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: ${(p) => p.band};
    border: ${(p) => p.band} solid ${(p) => p.color};
    border-radius: 50%;
    animation: lds-ring ${(p) => p.interval} cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p) => p.color} transparent transparent transparent;
  }

  .first {
    animation-delay: -0.45s;
  }
  .second {
    animation-delay: -0.3s;
  }
  .third {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const OuterContainer = styled('div')`
  font-size: 1.8rem;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default memo(Spinner);

import React from 'react';

import Color from 'color';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { backgroundColorForText } from '../../../lib/colorHelper';
import Theme from '../../../lib/Theme';

const Button = (props) => {
  const { color, className, children, onClick } = props;
  const filteredColor = backgroundColorForText(color);

  return (
    <StyledButton color={filteredColor} className={className} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  align-items: center;
  background-color: ${(p) => p.color};
  border: none;
  border-radius: ${Theme.borderRadius};
  box-sizing: border-box;
  color: ${(p) => (Color(p.color).isLight() ? Theme.colors.black : Theme.colors.white)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 2rem;
  padding: ${Theme.spacing.sm} ${Theme.spacing.xl};
  outline: none;
  overflow-y: hidden;
  position: relative;
  user-select: none;
  font-size: 1em;

  :hover {
    filter: brightness(${(p) => Color(p.color).lighten(0.1).string()});
  }

  :active,
  :focus {
    box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.25);
  }
`;

Button.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default Button;

import React, { useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { useUrlQueryParams } from 'lib/hooks';

import {
  DEFAULT_VIEW_DOCUMENT_COPY,
  EMBED_FRAME_CLASS,
  EMBED_FRAME_ID,
  EMBED_TYPE,
  HIDE_STUDY_COPY,
  QUESTION_LAYOUT_CENTER_CLASS_PREFIX,
  QUESTION_LAYOUT_SIDE_CLASS_PREFIX,
  SHOW_STUDY_COPY,
  SIGN_AGREEMENT_COPY,
  VIEW_PROTOTYPE_COPY,
  WEBSURVEY_FRAME_CLASS,
  WEBSURVEY_SIDEBAR_CLASS,
} from '../../../../lib/constants';
import { getEmbedFromQuestion, usePdfEmbedApi } from '../../../../lib/helpers';
import Theme from '../../../../lib/Theme';
import CircleExclamationSvg from '../../../svg/CircleExclamation';
import DownArrowCircleSvg from '../../../svg/DownArrowCircle';
import HelpChatSvg from '../../../svg/HelpChat';
import RightArrowCircleSvg from '../../../svg/RightArrowCircle';

import MobilePdfView from './MobilePdfView';

import './styles.css';

const PreviewMessage = ({ message }) => {
  return (
    <InfoMessageContainer role="alert" className="link-study-alert">
      <CircleExclamationSvg />
      <Message>{message}</Message>
    </InfoMessageContainer>
  );
};

// Layout for studies with SDK-embedded questions. Supports embedded content.
const StudyLayout = (props) => {
  const { childRef, children, color, currQuestionRef, introAlert, isEmbedViewed, logo, productName, showStripes } =
    props;
  const currQuestion = currQuestionRef.current;
  const headerRef = useRef(null);
  const embed = getEmbedFromQuestion(currQuestion);
  const { forceDevice } = useUrlQueryParams();
  const mobilePostfixClass = useMemo(() => {
    if (forceDevice) return forceDevice === 'mobile' ? '-mobile' : '';
    return window.innerWidth < 1030 ? '-mobile' : '';
  }, []);

  const isWebMatrix = mobilePostfixClass === '' && !!currQuestion?.props?.properties?.matrixColumn;
  const classPrefix = embed?.url ? QUESTION_LAYOUT_SIDE_CLASS_PREFIX : QUESTION_LAYOUT_CENTER_CLASS_PREFIX;

  const [showStudy, setShowStudy] = useState(true);
  const [showStudyButton, setShowStudyButton] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const shouldUsePdfEmbedApi = usePdfEmbedApi();

  useEffect(() => {
    if (childRef) childRef.current = studyToggle;
  }, []);

  useEffect(() => {
    setHeaderHeight(headerRef.current?.clientHeight);
  }, [currQuestion]);

  // Move logo to the side, use similar css as regular link one.
  const getLogo = (logo, defaultText) => {
    if (!logo) {
      return <CustomerLogoText className={`${classPrefix}-customer-logo-text`}>{defaultText}</CustomerLogoText>;
    }

    return <CustomerLogo className={`${classPrefix}-customer-logo`} src={logo} alt="logo" />;
  };

  // Toggle the study open and close view
  const studyToggle = () => {
    setShowStudy((showStudy) => !showStudy);
  };

  // Toggle the study open and close view
  const studyButtonToggle = () => {
    setShowStudyButton((showStudyButton) => !showStudyButton);
  };

  const embedFrame = () => {
    if (embed?.type === EMBED_TYPE.PDF && embed?.url && shouldUsePdfEmbedApi) {
      return <MobilePdfView className={EMBED_FRAME_CLASS} productName={productName} url={embed.url}></MobilePdfView>;
    }

    return (
      <EmbedFrame
        id={`${EMBED_FRAME_ID}${mobilePostfixClass}`}
        allowFullScreen
        className={EMBED_FRAME_CLASS}
        src={embed?.url ?? 'about:blank'}
      ></EmbedFrame>
    );
  };

  const questionView = () => {
    const viewEmbedText =
      embed?.type === EMBED_TYPE.PDF
        ? currQuestion?.props?.properties?.viewDocumentText || DEFAULT_VIEW_DOCUMENT_COPY
        : VIEW_PROTOTYPE_COPY;
    const studyToggleText = mobilePostfixClass ? viewEmbedText : HIDE_STUDY_COPY;
    return (
      <SurveyFrame className={`${WEBSURVEY_FRAME_CLASS}${mobilePostfixClass}`} isWebMatrix={isWebMatrix}>
        <SideBar
          className={`${WEBSURVEY_SIDEBAR_CLASS}${mobilePostfixClass}`}
          embeddedUrl={embed?.url}
          showStripes={showStripes}
        >
          <LinkStudyHeader ref={headerRef} className="survey-frame-header">
            {/* logo section */}
            {!(mobilePostfixClass && embed?.url && isEmbedViewed) && getLogo(logo, productName)}

            {/* hide study toggle button */}
            {(mobilePostfixClass ? embed?.url && isEmbedViewed : embed?.url) && (
              <HideStudyToggle
                onClick={studyToggle}
                className={`study-toggle-button study-toggle-button${mobilePostfixClass}`}
              >
                <SvgContainer>
                  {mobilePostfixClass && embed?.url ? <DownArrowCircleSvg /> : <RightArrowCircleSvg />}
                </SvgContainer>
                <p className="survey-toggle-text">{studyToggleText}</p>
              </HideStudyToggle>
            )}
          </LinkStudyHeader>

          {/* previews message banner */}
          {(showStudy || !embed?.url) && introAlert && <PreviewMessage message={introAlert} />}
          {/* question frame */}
          <StudyQuestionContainer headerHeight={headerHeight}>{children}</StudyQuestionContainer>
        </SideBar>
      </SurveyFrame>
    );
  };

  const showStudyText = mobilePostfixClass && embed?.type === EMBED_TYPE.PDF ? SIGN_AGREEMENT_COPY : SHOW_STUDY_COPY;

  return (
    <QuestionLayoutContainer
      className={`${classPrefix}-container`}
      style={!showStudy ? { height: '100vh' } : { height: '100%' }}
    >
      {/* Only show if embed url exists */}
      <CSSTransition in={!!embed?.url} timeout={300} unmountOnExit classNames={`iframe-animation${mobilePostfixClass}`}>
        {embedFrame()}
      </CSSTransition>
      <CSSTransition
        in={showStudyButton}
        timeout={150}
        unmountOnExit
        classNames="study-button-animation"
        onExited={() => setShowStudy(true)}
      >
        <ShowStudyToggle
          onClick={studyButtonToggle}
          className={`study-toggle-button show-study-button ${mobilePostfixClass && 'show-study-button-mobile'}`}
          style={{ backgroundColor: `${color}` }}
        >
          <HelpChatSvg />
          <ShowStudyToggleText className="survey-toggle-text">{showStudyText}</ShowStudyToggleText>
        </ShowStudyToggle>
      </CSSTransition>
      <CSSTransition
        in={showStudy}
        timeout={300}
        classNames="survey-animation"
        onEntering={() => setShowStudyButton(false)}
        onExited={() => setShowStudyButton(true)}
      >
        {questionView()}
      </CSSTransition>
    </QuestionLayoutContainer>
  );
};

StudyLayout.propTypes = {
  childRef: PropTypes.shape({ current: PropTypes.any }),
  /** Any react node */
  children: PropTypes.node,
  /** Brand color */
  color: PropTypes.string,
  currQuestionRef: PropTypes.shape({ current: PropTypes.any }),
  /** Text giving context to the survey */
  introAlert: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isEmbedViewed: PropTypes.bool,
  logo: PropTypes.string,
  productName: PropTypes.string,
  showStripes: PropTypes.bool,
};

PreviewMessage.propTypes = {
  message: PropTypes.string,
};

const QuestionLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LinkStudyHeader = styled.div`
  width: 100%;
  display: inline-flex;
`;

const EmbedFrame = styled.iframe``;

const SurveyFrame = styled.div`
  ${({ isWebMatrix }) => isWebMatrix && `min-width: 600px; max-width: min-content`};
  .ul-card__matrix {
    padding-right: 0;
  }
`;

const StudyQuestionContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - ${(p) => p.headerHeight}px);
`;

const SideBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  max-height: 100vh;
  padding-top: ${({ showStripes }) => (showStripes ? '40px' : 0)};
  position: ${({ embeddedUrl, showStripes }) => (embeddedUrl && showStripes ? 'relative' : 'unset')};
  width: 100%;
`;

const HideStudyToggle = styled.button`
  background-color: transparent;
  border: 1px solid ${Theme.colors.grey};
  height: 40px;
`;

const ShowStudyToggle = styled.button`
  border: 1px solid ${Theme.colors.black};
`;

const ShowStudyToggleText = styled.p`
  margin-left: 10px;
`;

const SvgContainer = styled.div`
  margin: 2px 8px 0 0;
`;

const CustomerLogoText = styled.h2`
  color: ${Theme.colors.offBlack};
`;

const CustomerLogo = styled.img``;

const InfoMessageContainer = styled.div`
  display: flex;
  color: #32a8ce;
  background: #e7f4f8;
  border: 1px solid #32a8ce;
  border-radius: 4px;
  font-size: 15px;
  padding: 10px;
  margin: 35px;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
`;

const Message = styled.div`
  margin-left: 10px;
  flex: 10;
`;

export default StudyLayout;

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MOBILE_PDF_DIV_ID } from '../../../../lib/constants';
import Theme from '../../../../lib/Theme';
import Spinner from '../../../core/Spinner';

// Adobe client ids from https://developer.adobe.com/console/projects
const ADOBE_EMBED_API_CLIENT_ID =
  window.location.hostname === 'localhost' ? '82a861cf0a854e7e97c2db6e6737e4ef' : 'cef03398c1ab4bc1934380d95e857ec3';
const ADOBE_EMBED_API_SDK_SOURCE = 'https://documentcloud.adobe.com/view-sdk/viewer.js';

const ADOBE_EMBED_API_SDK_READY_EVENT_NAME = 'adobe_dc_view_sdk.ready';
const PDF_LOAD_ERROR_MESSAGE = 'Unable to load PDF; please try again later.';

const pdfDisplayFilename = (productName) => `${[productName, 'Research Agreement'].join(' ')}.pdf`;

const MobilePdfView = ({ className, productName, url }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isPdfViewerReady, setIsPdfViewerReady] = useState(false);

  useEffect(() => {
    if (window.AdobeDC) {
      setIsPdfViewerReady(true);
      return;
    }

    window.document.addEventListener(ADOBE_EMBED_API_SDK_READY_EVENT_NAME, () => {
      setIsPdfViewerReady(true);
    });

    const element = window.document.createElement('script');
    element.src = ADOBE_EMBED_API_SDK_SOURCE;
    element.async = false;
    element.id = btoa(ADOBE_EMBED_API_SDK_SOURCE);
    element.onerror = () => setErrorMessage(PDF_LOAD_ERROR_MESSAGE);
    window.document.head.appendChild(element);
  }, []);

  useEffect(() => {
    if (!isPdfViewerReady) return;
    try {
      // ideally, we'd use the same adobeDCView and just call previewFile again, but there appears to be an error breaking this functionality
      // https://stackoverflow.com/questions/71437273/adobe-pdf-embed-api-can-not-change-the-pdf
      const adobeDCView = new window.AdobeDC.View({ clientId: ADOBE_EMBED_API_CLIENT_ID, divId: MOBILE_PDF_DIV_ID });
      adobeDCView.previewFile(
        {
          content: { location: { url } },
          metaData: { fileName: pdfDisplayFilename(productName) },
        },
        {
          showAnnotationTools: false,
          showFullScreeen: false,
          enableFormFilling: false,
          showDownloadPDF: false, // supported on Android but not iOS; more consistent to disable. https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/#mobile-support
          showPrintPDF: false, // unsupported on Android and iOS. https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/#mobile-support
          showThumbnails: false,
          showBookmarks: false,
          showPageControls: false,
          showLeftHandPanel: false,
        }
      );
    } catch (error) {
      console.error(error);
      setErrorMessage(PDF_LOAD_ERROR_MESSAGE);
    }
  }, [isPdfViewerReady, url]);

  return (
    <div className={className}>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {!errorMessage && !isPdfViewerReady && <Spinner color={Theme.colors.darkGrey} />}
      <div style={errorMessage ? { display: 'none' } : {}} id={MOBILE_PDF_DIV_ID}></div>
    </div>
  );
};

MobilePdfView.propTypes = {
  className: PropTypes.string,
  productName: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const ErrorMessage = styled('div')`
  padding: 2em 1.5em;
  font-size: 1.5em;
  color: ${Theme.colors.offBlack};
  align-items: center;
  justify-content: center;
`;

export default MobilePdfView;

// Used for unsubscribe, loading, locked, and other states that don't include an embedded study.
import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from '../../../../components/core/Card';
import CircleExclamationSvg from '../../../../components/svg/CircleExclamation';
import Theme from '../../../../lib/Theme';

const CenteredLayout = (props) => {
  const { children, color, introAlert, logo, productName } = props;

  const getLogo = (logo, defaultText) => {
    if (!logo) {
      return <CustomerLogoText>{defaultText}</CustomerLogoText>;
    }

    return <CustomerLogo src={logo} alt="logo" />;
  };

  return (
    <CenteredLayoutContainer>
      {getLogo(logo, productName)}
      {introAlert && <PreviewMessage message={introAlert} />}
      <StyledCard color={color}>{children}</StyledCard>
    </CenteredLayoutContainer>
  );
};

CenteredLayout.propTypes = {
  /** Any react node */
  children: PropTypes.node,
  color: PropTypes.string /** Brand color */,
  introAlert: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  logo: PropTypes.string,
  productName: PropTypes.string,
};

const PreviewMessage = ({ message }) => {
  return (
    <InfoMessageContainer role="alert">
      <CircleExclamationSvg />
      <Message>{message}</Message>
    </InfoMessageContainer>
  );
};

PreviewMessage.propTypes = {
  message: PropTypes.string,
};

const CenteredLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
  width: 95%;
`;

const CustomerLogoText = styled.h2`
  margin: 2.25em auto;
  font-size: 1.75rem;
  text-align: center;
  color: ${Theme.colors.offBlack};
`;

const CustomerLogo = styled.img`
  max-height: 80px;
  max-width: 185px;
  margin: 2.25em auto;
`;

const StyledCard = styled(Card)`
  padding: 2em 2.5em;
  margin: 0 -2.5em;
`;

const InfoMessageContainer = styled.div`
  display: flex;
  color: #32a8ce;
  background: #e7f4f8;
  border: 1px solid #32a8ce;
  border-radius: 4px;
  font-size: 15px;
  padding: 10px;
  margin: -1rem 0 1rem;
  align-items: center;
`;

const Message = styled.div`
  margin-left: 5px;
`;

export default CenteredLayout;

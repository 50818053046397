import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Logo from '../../components/core/Logo/Logo';
import SurveyAlreadyCompletedSvg from '../../components/svg/SurveyAlreadyCompleted';
import SurveyLockSvg from '../../components/svg/SurveyLock';
import NotFoundSvg from '../../components/svg/SurveyNotFound';
import { SURVEY_CLOSED_COPY } from '../../lib/constants';
import Theme from '../../lib/Theme';

export const SurveyLock = ({ color, marketingUrl, slugName, surveyId, branded, lockSurvey }) => {
  const canShowLogo = Boolean(slugName && surveyId && branded);
  const { headline, subheader } = lockSurvey;

  const getSvg = () => {
    if (
      headline === SURVEY_CLOSED_COPY.NOT_FOUND.headline ||
      headline === SURVEY_CLOSED_COPY.NOT_FOUND_PREVIEW.headline
    ) {
      return <StyledNotFoundSvg color={color} />;
    }

    if (subheader === SURVEY_CLOSED_COPY.ALREADY_COMPLETED.subheader) {
      return <StyledSurveyAlreadyCompletedSvg color={color} />;
    }

    return <StyledSurveyLockSvg color={color} />;
  };

  return (
    <SurveyLockContainer>
      {getSvg()}
      <StyledSurveyLockText>{headline}</StyledSurveyLockText>
      {subheader && <Caption>{subheader}</Caption>}
      {canShowLogo && <Logo marketingUrl={marketingUrl} slugName={slugName} surveyId={surveyId} />}
    </SurveyLockContainer>
  );
};

SurveyLock.propTypes = {
  branded: PropTypes.bool,
  color: PropTypes.string,
  marketingUrl: PropTypes.string,
  slugName: PropTypes.string,
  surveyId: PropTypes.number,
  lockSurvey: PropTypes.object,
};

const StyledSurveyLockSvg = styled(SurveyLockSvg)`
  width: 8.5rem;
  height: 8.5rem;
  margin: ${Theme.spacing.m} 0;
  color: ${(p) => p.color};
`;

const StyledSurveyAlreadyCompletedSvg = styled(SurveyAlreadyCompletedSvg)`
  width: 8.5rem;
  height: 8.5rem;
  margin: ${Theme.spacing.m} 0;
  color: ${(p) => p.color};
`;

const StyledNotFoundSvg = styled(NotFoundSvg)`
  width: 11rem;
  height: 8.5rem;
  margin: ${Theme.spacing.m} 0;
`;

const StyledSurveyLockText = styled('h5')`
  font-size: 1.5em;
  font-weight: 600;
  margin: ${Theme.spacing.xl} 0 0.75em 0;
  width: 100%;
  color: ${Theme.colors.offBlack};
`;

const Caption = styled('p')`
  font-size: 1.2em;
  margin: 0;
  color: gray;
  width: 100%;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
`;

const SurveyLockContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2em 2.5em 3.25em 2.5em;
  margin: 0px -2.5em;
  overflow-wrap: break-word;
  hyphens: auto;
  hyphenate-limit-lines: no-limit;
`;

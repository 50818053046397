import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { MOBILE_MAX_WIDTH } from './constants';

const FAKE_DESKTOP_USERAGENT =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 12_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15';
const FAKE_MOBILE_USERAGENT =
  'Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Mobile/15E148 Safari/604.1';

/**
 * This is only used for survey previews that want to force a certain device into the user agent
 */
export const useFakeUserAgentIfRequired = () => {
  const { forceDevice } = useUrlQueryParams();
  const fakeUserAgent = forceDevice === 'mobile' ? FAKE_MOBILE_USERAGENT : FAKE_DESKTOP_USERAGENT;
  if (forceDevice && window?.navigator && window?.navigator.userAgent !== fakeUserAgent) {
    Object.defineProperty(window.navigator, 'userAgent', {
      get: () => fakeUserAgent,
      configurable: true,
    });
  }
};

export const useIsMobile = () => {
  const { forceDevice } = useUrlQueryParams();
  if (forceDevice) {
    return forceDevice === 'mobile';
  }
  // need to know overall document width, which is why this setting is computed in the web-sdk controller,
  // so it needs to be calculated here too for parity
  // the "window.top === window.self" clause is to prevent mobile layout when link surveys are embedded inside a preview on the dashboard
  return window.top === window.self && document.body.clientWidth < MOBILE_MAX_WIDTH;
};

/**
 * Get an object that contains the keys/values from the current URL query
 * string
 *
 * @returns {Object}
 */
export const useUrlQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => getQueryParamObj(search), [search]);
};

/**
 * Convert a query string to an object
 *
 * @param {String} queryString - a url search query string
 * @returns {Object} JS object with keys/values from the query string
 */
const getQueryParamObj = (queryString: string) => {
  const qp = new URLSearchParams(queryString);
  return Array.from(qp.entries()).reduce<{ [key: string]: string }>((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});
};

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SURVEY_MESSAGE_ACTIONS } from 'lib/constants';
import { useFakeUserAgentIfRequired } from 'lib/hooks';

import { getSurveyPreview, getSurveyReview } from '../api';
import { getQueryParams } from '../lib/helpers';
import Theme from '../lib/Theme';

import CenteredLayout from './core/Layouts/CenteredLayout';
import Spinner from './core/Spinner';
import Survey from './Survey';

export const REVIEW_TYPE = {
  SURVEY: 'survey',
  TEMPLATE: 'template',
};

const SurveyReview = ({ reviewType }) => {
  useFakeUserAgentIfRequired();
  const { environmentId, surveyId, platform, templateId } = useParams();
  const { customMetadata, firstAnswer } = getQueryParams();
  const [survey, setSurvey] = useState();
  useDashboardRefresh(setSurvey);
  const sendMessage = (actionName, uuid) => {
    window.parent.postMessage({ actionName, data: { uuid } }, '*');
  };

  const [hasFinished, setHasFinished] = useState(false);

  useEffect(() => {
    if (survey) return;

    switch (reviewType) {
      case REVIEW_TYPE.TEMPLATE:
        getSurveyPreview(environmentId, platform, templateId, firstAnswer).then(setSurvey);
        break;
      case REVIEW_TYPE.SURVEY:
        getSurveyReview(environmentId, surveyId, firstAnswer).then(setSurvey);
        break;
      default:
        console.error(`Invalid reviewType ${reviewType}; cannot load survey.`);
        break;
    }
  }, [survey]);
  const handleSurveyComplete = () => {
    setHasFinished(true);
    sendMessage(SURVEY_MESSAGE_ACTIONS.COMPLETE_SURVEY, surveyId || templateId);
    setSurvey(null);
  };

  if (!survey || survey.questions?.length === 0) {
    return (
      <CenteredLayout>
        <LoadingContainer>
          <StyledLoadingText>Loading...</StyledLoadingText>
          <Spinner color={Theme.colors.darkGrey} />
        </LoadingContainer>
      </CenteredLayout>
    );
  }

  return (
    <Survey
      surveyDefinition={survey}
      isPreview={true}
      environmentId={environmentId}
      customMetadata={customMetadata}
      handleSurveyComplete={handleSurveyComplete}
      forceStartFromBeginning={hasFinished}
    />
  );
};

/**
 * Listen for "message" events on the window object and force this app to
 * re-fetch the survey data if the "refresh" message is received. This message
 * is used by the dashboard for providing a live preview of a websurveys by
 * loading it in an iframe.
 * @param {Function} setSurvey survey setter
 */
const useDashboardRefresh = (setSurvey) => {
  useEffect(() => {
    const handler = ({ data }) => data === 'refresh' && setSurvey(null);
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);
};

SurveyReview.propTypes = {
  reviewType: PropTypes.oneOf([REVIEW_TYPE.SURVEY, REVIEW_TYPE.TEMPLATE]),
};

const StyledLoadingText = styled('h5')`
  font-size: 1.5em;
  margin: ${Theme.spacing.xl} 0;
`;

const LoadingContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 2.5em;
  margin: 0px -2.5em;
`;

export default SurveyReview;

export const SURVEY_CLOSED_COPY = {
  STATUS: { headline: 'Survey Completed', subheader: 'This survey is no longer accepting responses.' },
  ALREADY_COMPLETED: { headline: 'Survey Completed', subheader: 'Your survey response has already been received.' },
  NOT_FOUND: { headline: 'Survey not found', subheader: 'Please check the URL and try again.' },
  NOT_FOUND_PREVIEW: { headline: 'Survey preview not found', subheader: 'Please check the URL and try again.' },
  INVALID_DEVICE_MOBILE: {
    headline: 'Device Not Supported',
    subheader:
      'This survey is only compatible on larger screens due to formatting constraints. Please switch to a laptop or desktop computer to complete this study.',
  },
  INTERNAL_ERROR: {
    headline: 'System error',
    subheader: 'Sorry, there was an internal error loading the survey. Please try again later.',
  },
};

export const QUESTION_TYPES = {
  OPEN_ENDED_TEXT: 'open',
  MULTIPLE_CHOICE_SINGLE_ANSWER: 'multiplechoice',
  LIKERT_SCALE: 'likert',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER: 'multipleselect',
  NPS: 'nps',
  TEXT_URL_PROMPT: 'texturlprompt',
  VIDEO_VOICE: 'videovoice',
  CONSENT_LEGAL: 'consentlegal',
  RECORDED_TASK: 'recordedtask',
  MATRIX: 'matrix',
};

export const INTERNAL_ERROR_SURVEY = {
  lockSurvey: SURVEY_CLOSED_COPY.INTERNAL_ERROR,
};

export const NOT_FOUND_SURVEY = {
  lockSurvey: SURVEY_CLOSED_COPY.NOT_FOUND,
};

export const NOT_FOUND_SURVEY_PREVIEW = {
  lockSurvey: SURVEY_CLOSED_COPY.NOT_FOUND_PREVIEW,
};

export const INVALID_DEVICE_MOBILE_SURVEY = {
  lockSurvey: SURVEY_CLOSED_COPY.INVALID_DEVICE_MOBILE,
};

export const SURVEY_FRAME_ID = 'ul-survey-frame';
export const MOBILE_PDF_DIV_ID = 'ul-mobile-pdf';

// used for styling in userleap-web-sdk
export const WEBSURVEY_FRAME_CLASS = 'ul-websurvey-frame';
export const WEBSURVEY_SIDEBAR_CLASS = 'ul-websurvey-sidebar';
export const QUESTION_LAYOUT_CENTER_CLASS_PREFIX = 'ul-question-center-layout';
export const QUESTION_LAYOUT_SIDE_CLASS_PREFIX = 'ul-question-side-layout';
export const EMBED_FRAME_CLASS = 'ul-embed-frame';
export const EMBED_FRAME_ID = EMBED_FRAME_CLASS;
export const FILTER_BLUR_CLASS = 'ul-filter-blur';

export const EXPERIMENT_FLAGS = {
  SURVEY_MOBILE_STYLING: 'survey-mobile-styling',
};

export const MOBILE_MAX_WIDTH = 500;

export const EMBED_TYPE = {
  PROTOTYPE: 'prototype',
  PDF: 'pdf',
};

// see also userleap-web-sdk/src/shared/constants.js
export const VIEW_PROTOTYPE_COPY = 'View Prototype';
export const DEFAULT_VIEW_DOCUMENT_COPY = 'View Document';
export const DEFAULT_LIKERT_RANGE = 5;

export const HIDE_STUDY_COPY = 'Hide Study';
export const SHOW_STUDY_COPY = 'Show Study';
export const SIGN_AGREEMENT_COPY = 'Sign Agreement';

//Parallel definition at: services/app/src/hooks/constants
export const SURVEY_MESSAGE_ACTIONS = {
  COMPLETE_SURVEY: 'completeSurvey',
};

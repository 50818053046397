import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import Theme from '../../../../lib/Theme';

import './styles.css';

const ConceptTestSurveyLayout = (props) => {
  let [showSurvey, setShowSurvey] = useState(true);
  const { children, conceptTesting, logo, productName } = props;

  const SURVEY_TAKING_OVERLAY_ID = 'survey-taking-overlay';

  const getLogo = (logo, defaultText) => {
    if (!logo) {
      return <CustomerLogoText>{defaultText}</CustomerLogoText>;
    }

    return <CustomerLogo src={logo} alt="logo" />;
  };
  const surveyToggle = () => {
    setShowSurvey((showSurvey) => !showSurvey);
    if (showSurvey) document.getElementById(SURVEY_TAKING_OVERLAY_ID).classList.add('survey-taking-overlay-show');
    else document.getElementById(SURVEY_TAKING_OVERLAY_ID).classList.remove('survey-taking-overlay-show');
  };

  const conceptTestBottomBar = (logo, defaultText) => {
    return (
      <BottomBar>
        {getLogo(logo, defaultText)}
        <SurveyToggle onClick={surveyToggle} className="survey-toggle">
          <p className="survey-toggle-text">{!showSurvey ? 'Show Survey' : 'Hide Survey'}</p>
          <SvgContainer>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5L5 1L9 5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </SvgContainer>
        </SurveyToggle>
      </BottomBar>
    );
  };

  return (
    <ConceptTestSurveyLayoutContainer>
      <div id={SURVEY_TAKING_OVERLAY_ID}></div>
      <PrototypeFrame src={conceptTesting.length && conceptTesting[0].prototypeUrl} allowFullScreen></PrototypeFrame>
      <SurveyFrame className="mobile-active">
        <CSSTransition in={showSurvey} timeout={250} classNames="survey-animation">
          {children}
        </CSSTransition>
      </SurveyFrame>
      {conceptTestBottomBar(logo, productName)}
    </ConceptTestSurveyLayoutContainer>
  );
};

ConceptTestSurveyLayout.propTypes = {
  productName: PropTypes.string,
  logo: PropTypes.string,
  /** Any react node */
  children: PropTypes.node,
  /** Text giving context to the survey */
  introAlert: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Brand color */
  color: PropTypes.string,
  conceptTesting: PropTypes.array,
};

const ConceptTestSurveyLayoutContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const PrototypeFrame = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;
const SurveyFrame = styled.div`
  position: absolute;
  bottom: 60px;
  right: 5px;
  margin-bottom: 5px;
  width: 355px;
  z-index: 2;
`;

const BottomBar = styled.div`
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  z-index: 2;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #f0f0f1;
  filter: drop-shadow(0px -2px 10px rgba(0, 0, 0, 0.05));
`;

const SurveyToggle = styled.button`
  height: 40px;
  width: 127px;
  right: 20px;
  position: absolute;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border: none;
  border-radius: 4px;
  padding: 0 15px;
  background-color: transparent;
`;

const SvgContainer = styled.div`
  margin: 0 0 0 10px;
`;

const CustomerLogoText = styled.h2`
  max-height: 30px;
  font-size: 1.75rem;
  text-align: center;
  color: ${Theme.colors.offBlack};
  margin-left: 20px;
`;

const CustomerLogo = styled.img`
  max-height: 30px;
  margin-left: 20px;
`;

export default ConceptTestSurveyLayout;

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { resubscribe } from '../../api';
import Button from '../../components/core/Button';
import CenteredLayout from '../../components/core/Layouts/CenteredLayout';
import Spinner from '../../components/core/Spinner';
import Theme from '../../lib/Theme';

const STATUS = {
  IN_PROGRESS: 'inProgress',
  SUCCESS: 'success',
  ERROR: 'error',
};

const Resubscribe = ({ surveyDefinition, environmentId }) => {
  const color = surveyDefinition.border || Theme.colors.darkGrey;
  const { logo, productName, visitorId, visitorToken, platform } = surveyDefinition;
  const [status, setStatus] = useState(STATUS.IN_PROGRESS);

  useEffect(() => {
    document.title = `Resubscribe to ${productName} research`;
  }, []);

  useEffect(() => {
    if (status !== STATUS.IN_PROGRESS) {
      return;
    }

    resubscribe(environmentId, visitorId, visitorToken, platform).then((response) =>
      setStatus(response ? STATUS.SUCCESS : STATUS.ERROR)
    );
  }, [status]);

  return (
    <CenteredLayout logo={logo} color={color}>
      <ResubscribeContainer>
        <StyledResubscribeText>
          {status === STATUS.IN_PROGRESS && 'Resubscribing...'}
          {status === STATUS.SUCCESS && `You have been resubscribed to email studies from ${productName}.`}
          {status === STATUS.ERROR && 'There was an error and you were not successfully resubscribed.'}
        </StyledResubscribeText>
        {status === STATUS.IN_PROGRESS && <Spinner color={Theme.colors.darkGrey} />}
        {status === STATUS.ERROR && (
          <ButtonContainer>
            <Button color={Theme.colors.darkGrey} onClick={() => setStatus(STATUS.IN_PROGRESS)}>
              Retry
            </Button>
          </ButtonContainer>
        )}
      </ResubscribeContainer>
    </CenteredLayout>
  );
};

Resubscribe.propTypes = {
  surveyDefinition: PropTypes.object,
  environmentId: PropTypes.string,
};

const StyledResubscribeText = styled('h5')`
  font-size: 1.5em;
  margin: ${Theme.spacing.xl} 0;
  color: ${Theme.colors.offBlack};
`;

const ResubscribeContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 2.5em;
  margin: 0px -2.5em;
`;

const ButtonContainer = styled('div')`
  margin-top: ${Theme.spacing.sm};
  text-decoration: none;
`;

export default Resubscribe;

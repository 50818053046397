import { EventEmitter } from 'events';

import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { validate as validateUuid } from 'uuid';

import { environment } from '@sprigShared/environment';

import {
  INTERNAL_ERROR_SURVEY,
  NOT_FOUND_SURVEY,
  NOT_FOUND_SURVEY_PREVIEW,
  SURVEY_CLOSED_COPY,
  SURVEY_FRAME_ID,
} from './lib/constants';
import { validateAnswer, isAllDigits, getSurveyValidityError } from './lib/helpers';

export const getApiBaseUrl = () => environment.apiUrl;

const getSdkViewUrl = () => {
  const sdkViewFilename = '/sprig-web-view-sdk-latest.js';

  return environment.cdnUrl + sdkViewFilename;
};

const eventEmitter = new EventEmitter();

export const setSdkWindowConfig = ({
  environmentId,
  survey,
  isPreview,
  customMetadata,
  useMobileStyling,
  useDesktopPrototype = false,
  questionIdx = 0,
}) => {
  const headers = {
    'Content-Type': 'application/json',
    'userleap-platform': survey.platform,
    'x-ul-environment-id': environmentId,
    'x-ul-visitor-id': survey.visitorId,
    Authorization: `Bearer ${survey.visitorToken}`,
  };

  const configureExitOnOverlayClick = () => {};

  const frame = document.getElementById(SURVEY_FRAME_ID);
  const visitorAttributes = {
    externalUserId: customMetadata ? customMetadata.user_id : undefined,
    email: customMetadata ? customMetadata.email : undefined,
  };
  window.__cfg = {
    answers: survey.firstAnswer ? [{ questionId: survey.questions[0].name, value: survey.firstAnswer }] : [],
    apiURL: getApiBaseUrl(),
    border: survey.border,
    cards: survey.questions,
    configureExitOnOverlayClick,
    customMetadata,
    endCard: survey.endCard || { headline: 'Thanks for your input!' },
    envId: environmentId,
    eventEmitFn: eventEmitter.emit.bind(eventEmitter),
    experimentFlags: survey.experimentFlags,
    frame,
    headers,
    isPreview,
    marketingUrl: survey.marketingUrl,
    forceBrandedLogo: survey.forceBrandedLogo,
    startingQuestionIdx: questionIdx,
    responseGroupUid: survey.responseGroupUid,
    showStripes: survey.showStripes,
    showSurveyBrand: survey.showSurveyBrand,
    slugName: survey.slugName,
    surveyId: survey.surveyId,
    useDesktopPrototype,
    useMobileStyling,
    userId: survey.visitorId,
    viewDocument: document,
    viewWindow: window,
    tabTitle: document.title,
    visitorAttributes,
  };
};

// Optional callback. Should contain object with name and callbackFn to properly subscribe to the event
export const insertSdkScript = (eventCallbacks = []) => {
  const script = document.createElement('script');
  script.async = 1;
  script.src = getSdkViewUrl();
  const anchor = document.getElementsByTagName('script')[0];
  anchor.parentNode.insertBefore(script, anchor);

  // subscribe to event emitter for different survey question events
  if (eventCallbacks.length > 0) {
    eventCallbacks.forEach((event) => {
      eventEmitter.on(event.name, event.callbackFn);
    });
  }
};

// Pass in array of the name of events to unsubscribe.
export const unsubscribeEvents = (listeners = []) => {
  listeners.forEach((listener) => {
    eventEmitter.removeListener(listener.name, listener.callbackFn);
  });
};

const handleGetSurveyErrors = (err, environmentId, surveyId, emailSurveyId) => {
  if (err.response?.status === 412) {
    const { data } = err.response;
    data.surveyData.lockSurvey = SURVEY_CLOSED_COPY.ALREADY_COMPLETED;
    return data.surveyData;
  }

  Sentry.withScope(function (scope) {
    scope.setTag('environmentId', environmentId);
    scope.setTag('surveyId', surveyId);
    scope.setTag('emailSurveyId', emailSurveyId);
    Sentry.captureException(err);
  });
  if ([400, 404].includes(err.response?.status)) {
    return NOT_FOUND_SURVEY;
  }

  return INTERNAL_ERROR_SURVEY;
};

export const getEmailSurvey = async (environmentId, emailSurveyId, firstAnswer) => {
  if (!environmentId || !emailSurveyId) {
    return NOT_FOUND_SURVEY;
  }
  try {
    const { data } = await axios.get(`${getApiBaseUrl()}/1/environments/${environmentId}/emailsurvey/${emailSurveyId}`);

    // If we have an answer sent in, check that it's valid for the first question.
    // If not, don't save it, and display the first question
    if (firstAnswer && data.surveyData?.questions?.[0] && validateAnswer(data.surveyData.questions[0], firstAnswer)) {
      data.surveyData.firstAnswer = firstAnswer;
    }

    const surveyValidationError = getSurveyValidityError(data.surveyData);
    if (surveyValidationError) return surveyValidationError;

    return data.surveyData;
  } catch (err) {
    return handleGetSurveyErrors(err, environmentId, null, emailSurveyId);
  }
};

export const getEmailSurveyForTestUnsubscribe = async (environmentId, surveyUuid) => {
  if (!environmentId || !surveyUuid) {
    return NOT_FOUND_SURVEY;
  }

  try {
    const { data } = await axios.get(
      `${getApiBaseUrl()}/1/environments/${environmentId}/emailsurvey/${surveyUuid}/testUnsubscribe`
    );
    return data.surveyData;
  } catch (err) {
    return handleGetSurveyErrors(err, environmentId, null, surveyUuid);
  }
};

export const getLinkSurvey = async (environmentId, surveyId, firstAnswer, customMetadata) => {
  if (!environmentId || !surveyId) {
    console.error(`Attempted to getLinkSurvey with falsy arguments ${JSON.stringify({ environmentId, surveyId })}`);
    return NOT_FOUND_SURVEY;
  }

  try {
    const linkRequest = `${getApiBaseUrl()}/1/environments/${environmentId}/emailsurvey/${surveyId}/link`;
    let visitorRequest = `${getApiBaseUrl()}/1/environments/${environmentId}/emailsurvey/${surveyId}/visitor`;

    // validates customMetadata
    const isUserIdValid = customMetadata?.user_id && !customMetadata.user_id.includes('user_id');
    const isVisitorIdValid =
      customMetadata?.visitor_id &&
      !customMetadata.visitor_id.includes('visitor_id') &&
      validateUuid(customMetadata.visitor_id);
    const isEmailValid = customMetadata?.email;

    let params = [];
    if (isUserIdValid) params.push(`user_id=${customMetadata.user_id}`);
    if (isVisitorIdValid) params.push(`visitor_id=${customMetadata.visitor_id}`);
    if (isEmailValid) params.push(`email=${customMetadata.email}`);

    // Add params if there's user or visitor id
    if (params.length > 0) visitorRequest += `?${params.join('&')}`;

    const [surveyResponse, visitorResponse] = await Promise.all([axios.get(linkRequest), axios.get(visitorRequest)]);
    const surveyData = surveyResponse.data.surveyData;
    // If we have an answer sent in, check that it's valid for the first question.
    // If not, don't save it, and display the first question
    if (firstAnswer && surveyData?.questions?.[0] && validateAnswer(surveyData.questions[0], firstAnswer)) {
      surveyData.firstAnswer = firstAnswer;
    }

    const surveyValidationError = getSurveyValidityError(surveyData);
    if (surveyValidationError) return surveyValidationError;

    return { ...surveyData, ...visitorResponse.data };
  } catch (err) {
    return handleGetSurveyErrors(err, environmentId, surveyId, null);
  }
};

const getPreviewFromApi = async (url, firstAnswer, environmentId, surveyId, surveyTemplateId) => {
  try {
    const { data } = await axios.get(url);

    // If we have an answer sent in, check that it's valid for the first question.
    // If not, don't save it, and display the first question
    if (firstAnswer && data?.questions?.[0] && validateAnswer(data.questions[0], firstAnswer)) {
      data.firstAnswer = firstAnswer;
    }

    const surveyValidationError = getSurveyValidityError(data);
    if (surveyValidationError) return surveyValidationError;
    return data;
  } catch (err) {
    Sentry.withScope(function (scope) {
      scope.setTag('environmentId', environmentId);
      scope.setTag('surveyId', surveyId);
      scope.setTag('surveyTemplateId', surveyTemplateId);
      Sentry.captureException(err);
    });
    return NOT_FOUND_SURVEY_PREVIEW;
  }
};

export const getSurveyReview = async (environmentId, surveyId, firstAnswer) => {
  if (!environmentId || !surveyId) {
    console.error(`Attempted to getSurveyReview with falsy arguments ${JSON.stringify({ environmentId, surveyId })}`);
    return NOT_FOUND_SURVEY_PREVIEW;
  }
  if (!isAllDigits(surveyId) && !validateUuid(surveyId)) {
    console.error(`Attempted to getSurveyReview with invalid surveyId: ${surveyId}`);
    return NOT_FOUND_SURVEY_PREVIEW;
  }
  return getPreviewFromApi(
    `${getApiBaseUrl()}/1/environments/${environmentId}/emailsurvey/${surveyId}/review`,
    firstAnswer,
    environmentId,
    surveyId,
    null
  );
};

export const getSurveyPreview = async (environmentId, platform, surveyTemplateId, firstAnswer) => {
  if (!environmentId || !platform || !surveyTemplateId) {
    console.error(
      `Attempted to getSurveyPreview with falsy arguments ${JSON.stringify({
        environmentId,
        platform,
        surveyTemplateId,
      })}`
    );
    return NOT_FOUND_SURVEY_PREVIEW;
  }
  if (!isAllDigits(surveyTemplateId) && !validateUuid(surveyTemplateId)) {
    console.error(`Attempted to getSurveyReview with invalid surveyTemplateId: ${surveyTemplateId}`);
    return NOT_FOUND_SURVEY_PREVIEW;
  }

  return getPreviewFromApi(
    `${getApiBaseUrl()}/1/environments/${environmentId}/emailsurvey/${platform}/${surveyTemplateId}/preview`,
    firstAnswer,
    environmentId,
    null,
    surveyTemplateId
  );
};

export const unsubscribe = async (environmentId, visitorId, visitorToken, platform) => {
  if (!environmentId || !visitorId || !visitorToken || !platform) {
    console.error(
      `Attempted to unsubscribe with falsy arguments ${JSON.stringify({
        environmentId,
        visitorId,
        visitorToken,
        platform,
      })}`
    );
    return null;
  }

  try {
    return axios.put(
      `${getApiBaseUrl()}/2/environments/${environmentId}/visitors/${visitorId}/attributes`,
      { '!email_opt_out': true },
      {
        headers: { Authorization: `Bearer ${visitorToken}`, 'userleap-platform': platform },
      }
    );
  } catch (err) {
    Sentry.withScope(function (scope) {
      scope.setTag('environmentId', environmentId);
      scope.setExtra('visitorId', visitorId);
      Sentry.captureException(err);
    });
    return null;
  }
};

export const resubscribe = async (environmentId, visitorId, visitorToken, platform) => {
  if (!environmentId || !visitorId || !visitorToken || !platform) {
    console.error(
      `Attempted to resubscribe with falsy arguments ${JSON.stringify({
        environmentId,
        visitorId,
        visitorToken,
        platform,
      })}`
    );
    return null;
  }

  try {
    return axios.delete(`${getApiBaseUrl()}/2/environments/${environmentId}/visitors/${visitorId}/attributes`, {
      headers: { Authorization: `Bearer ${visitorToken}`, 'userleap-platform': platform },
      data: { delete: ['!email_opt_out'] },
    });
  } catch (err) {
    Sentry.withScope(function (scope) {
      scope.setTag('environmentId', environmentId);
      scope.setExtra('visitorId', visitorId);
      Sentry.captureException(err);
    });
    return null;
  }
};

import React from 'react';

const DownArrowCircleSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 9L10 12L7 9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="1.25" y="1.25" width="17.5" height="17.5" rx="8.75" stroke="black" strokeWidth="1.5" />
  </svg>
);

export default DownArrowCircleSvg;

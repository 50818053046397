import styled from 'styled-components';

import Theme from '../../../lib/Theme';

const Card = styled.div`
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${Theme.spacing.lg};
`;

export default Card;

import React from 'react';

const CircleExclamationSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13A6 6 0 107 1a6 6 0 000 12zm0 1A7 7 0 107 0a7 7 0 000 14z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3a.5.5 0 01.5.5v4.2a.5.5 0 01-1 0V3.5A.5.5 0 017 3z"
      fill="currentColor"
    />
    <path d="M7.7 10.5a.7.7 0 11-1.4 0 .7.7 0 011.4 0z" fill="currentColor" />
  </svg>
);

export default CircleExclamationSvg;

// DO NOT EDIT. ORIGINAL COPY IN WEB-SDK. WILL REMOVE ONCE WEB-SDK HAS BEEN MOVED TO MONOREPO.
// events not intended to be used externally and not in the documentation
export const internalEvents = {
  name: {
    VERIFY_VIEW_VERSION: 'verify.view.version',
    CURRENT_QUESTION: 'survey.question',
    VIEW_PROTOTYPE_CLICK: 'question.prototype.click',
    VIEW_AGREEMENT_CLICK: 'question.agreement.click',
    RECORDED_TASK_START: 'recorded.task.start',
    RECORDED_TASK_PERMISSION_SCREEN: 'recorded.task.permission.screen',
    SURVEY_COMPLETE: 'survey.complete',
  },
  data: {
    VIEW_VERSION: 'view.version',
    QUESTION_ID: 'qid',
    PROPS: 'props',
  },
};

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import { unsubscribe } from '../../api';
import Button from '../../components/core/Button';
import CenteredLayout from '../../components/core/Layouts/CenteredLayout';
import Spinner from '../../components/core/Spinner';
import { NOT_FOUND_SURVEY } from '../../lib/constants';
import Theme from '../../lib/Theme';

const STATUS = {
  IN_PROGRESS: 'inProgress',
  SUCCESS: 'success',
  ERROR: 'error',
  TEST_SUCCESS: 'testSuccess',
};

const Unsubscribe = ({ surveyDefinition, environmentId, testMode }) => {
  const color = surveyDefinition.border || Theme.colors.darkGrey;
  const { logo, productName, visitorId, visitorToken, platform } = surveyDefinition;
  const [status, setStatus] = useState(STATUS.IN_PROGRESS);

  let { surveyToken } = useParams();

  useEffect(() => {
    document.title = `Unsubscribe from ${productName} research`;
  }, []);

  useEffect(() => {
    if (status !== STATUS.IN_PROGRESS) {
      return;
    }

    if (testMode) setStatus(surveyDefinition === NOT_FOUND_SURVEY ? STATUS.ERROR : STATUS.TEST_SUCCESS);
    else
      unsubscribe(environmentId, visitorId, visitorToken, platform).then((response) =>
        setStatus(response ? STATUS.SUCCESS : STATUS.ERROR)
      );
  }, [status]);

  const linkStyle = {
    textDecoration: 'none',
  };

  return (
    <CenteredLayout logo={logo} color={color}>
      <UnsubscribeContainer>
        <StyledUnsubscribeText>
          {status === STATUS.IN_PROGRESS && 'Unsubscribing...'}
          {status === STATUS.SUCCESS && 'You have been unsubscribed!'}
          {status === STATUS.ERROR && 'There was an error and you were not successfully unsubscribed.'}

          {/* Note: In the test unsubscribe screen, it is okay to not add the resubscribe button. */}
          {status === STATUS.TEST_SUCCESS && 'Test unsubscribe was successful!'}
        </StyledUnsubscribeText>
        {status === STATUS.SUCCESS && (
          <StyledUnsubscribeSmallText>
            {`You will no longer receive email studies from ${productName}.`}
          </StyledUnsubscribeSmallText>
        )}
        {status === STATUS.IN_PROGRESS && <Spinner color={Theme.colors.darkGrey} />}
        {status === STATUS.SUCCESS && (
          <ButtonContainer>
            <Link to={`/${surveyToken}/resubscribe`} style={linkStyle}>
              <Button type="submit" color={color}>
                Resubscribe
              </Button>
            </Link>
          </ButtonContainer>
        )}
        {status === STATUS.ERROR && (
          <ButtonContainer>
            <Button color={Theme.colors.darkGrey} onClick={() => setStatus(STATUS.IN_PROGRESS)}>
              Retry
            </Button>
          </ButtonContainer>
        )}
      </UnsubscribeContainer>
    </CenteredLayout>
  );
};

Unsubscribe.propTypes = {
  surveyDefinition: PropTypes.object,
  environmentId: PropTypes.string,
  testMode: PropTypes.bool,
};

const StyledUnsubscribeText = styled('h5')`
  font-size: 1.5em;
  margin: ${Theme.spacing.xl} 0;
  color: ${Theme.colors.offBlack};
`;

const StyledUnsubscribeSmallText = styled('h6')`
  font-size: 1em;
  color: ${Theme.colors.offBlack};
`;

const UnsubscribeContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 2.5em;
  margin: 0px -2.5em;
`;

const ButtonContainer = styled('div')`
  margin-top: ${Theme.spacing.sm};
  text-decoration: none;
`;

export default Unsubscribe;

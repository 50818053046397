import mobile from 'is-mobile';
import get from 'lodash/get';
import parser from 'ua-parser-js';
import { validate as validateUuid } from 'uuid';

import { PROTOTYPE_SERVICES, getPrototypeServiceFromURL } from '@sprigShared/prototype-helpers';

import { EMBED_TYPE, QUESTION_TYPES, INVALID_DEVICE_MOBILE_SURVEY, DEFAULT_LIKERT_RANGE } from './constants';

// source: https://masteringjs.io/tutorials/fundamentals/query-string
export const getQueryParams = (url = window.location.href) => {
  if (url.indexOf('?') === -1) {
    return { customMetadata: {} };
  }

  const urlEntries = url
    .slice(url.indexOf('?') + 1)
    .split('&')
    .map((str) => str.split('=').map((v) => decodeURIComponent(v)));

  const customMetadata = {};
  let firstAnswer; // hardcoded in our email/link platform with the key 'r'

  for (let [key, value] of urlEntries) {
    if (key === 'r') {
      firstAnswer = value;
    } else {
      customMetadata[key] = value;
    }
  }
  return { customMetadata, firstAnswer };
};

const isHexDigitsOnlyRegex = /^[0-9A-Fa-f]+$/;
const isAsciiRegex = /^[\x20-\x7F]*$/;
export const isAllDigits = (n) => !!n.match(/^[0-9]+$/);

export const parseSurveyToken = (surveyToken) => {
  if (!surveyToken) {
    return {};
  }

  let environmentId;
  let encodedSurveyId;
  try {
    // new link surveys are encoded in base64 to reduce space
    let decodedString;
    if (isHexDigitsOnlyRegex.test(surveyToken)) {
      decodedString = Buffer.from(surveyToken, 'hex').toString();
    } else {
      decodedString = Buffer.from(surveyToken, 'base64').toString();
    }

    if (!isAsciiRegex.test(decodedString)) {
      return {};
    }

    [environmentId, encodedSurveyId] = decodedString.split('~');
  } catch (error) {
    // may be a manually-entered, invalid URL
    console.error(error);
    return {};
  }

  if (!environmentId || !encodedSurveyId) {
    return {};
  }

  const params = { environmentId };
  if (encodedSurveyId.indexOf('sid:') === 0) {
    // may be an int id or uuid
    const surveyId = encodedSurveyId.substr(4);
    if (!isAllDigits(surveyId) && !validateUuid(surveyId)) {
      return {};
    }

    params.surveyId = surveyId;
  } else {
    params.emailSurveyId = encodedSurveyId;
  }

  return params;
};

const validateMultipleChoice = (options, answer) => {
  const selectedOption = options.find((o) => o.value === answer);
  //For 'Other' options, do not skip as the user will need to enter their text
  return selectedOption && !selectedOption.optionProperties?.allowsTextEntry;
};

const validateIntegerWithRange = (answer, range) => {
  const num = parseInt(answer, 10);

  if (isNaN(num)) {
    return false;
  }
  return range.includes(num);
};

export const validateAnswer = (question, answer) => {
  switch (question.type) {
    case 'likert': {
      const questionRange = Number(question.props.properties.range) || DEFAULT_LIKERT_RANGE;
      return validateIntegerWithRange(answer, [...Array(questionRange + 1).keys()].slice(1));
    }

    case 'multiplechoice':
      return validateMultipleChoice(question.props.options, answer);

    case 'nps':
      return validateIntegerWithRange(answer, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    default:
      return false;
  }
};

const FIGMA_PREFIX = 'https://www.figma.com/embed?embed_host=share&url='; // embed prefix string
const FIGMA_POSTFIX = '%26hide-ui=1'; // custom post fix to disable ui
const FIGMA_SCALE_DOWN_STR = 'scaling=scale-down';
const OVERRIDE_SCALING_STRS = ['scaling=contain', 'scaling=min-zoom']; // override these scaling settings
const SKETCH_INFIX = '.com/embed/';
const PROTOPIE_INFIXES = [
  { target: 'ui=true', desired: 'ui=false' },
  { target: 'scaleToFit=false', desired: 'scaleToFit=true' },
];
const AXURE_POSTFIXES = [
  { target: '&sc=', override: '&sc=1', desired: '&sc=2' },
  { target: '&c', desired: '&c=1' },
];
const ADOBE_INFIX = { target: '/view/', desired: '/embed/' };

/**
 * Takes in the service and url of a prototype and changes the url as needed to allow an embed
 * @param {String} service - The prototyping service used to create the prototype's URL
 * @param {String} url - The link the user input for their prototype
 * @returns An updated url prefixed/postfixed as necessary to allow an embed in the websurvey
 */
const serviceToConceptTestUrl = (service, url) => {
  let correctedUrl = url;
  switch (service) {
    case PROTOTYPE_SERVICES.FIGMA:
      OVERRIDE_SCALING_STRS.forEach((scalingStr) => {
        if (correctedUrl.includes(scalingStr)) correctedUrl = correctedUrl.replace(scalingStr, FIGMA_SCALE_DOWN_STR);
      });

      if (!url.startsWith(FIGMA_PREFIX)) {
        correctedUrl = `${FIGMA_PREFIX}${encodeURIComponent(correctedUrl)}`;
      }
      if (!correctedUrl.endsWith(FIGMA_POSTFIX)) {
        correctedUrl = `${correctedUrl}${FIGMA_POSTFIX}`;
      }
      break;
    case PROTOTYPE_SERVICES.SKETCH:
      if (!correctedUrl.includes(SKETCH_INFIX)) correctedUrl = correctedUrl.replace('.com/', SKETCH_INFIX);
      break;
    case PROTOTYPE_SERVICES.PROTOPIE:
      PROTOPIE_INFIXES.forEach((infixCheck) => {
        if (correctedUrl.includes(infixCheck.target))
          correctedUrl = correctedUrl.replace(infixCheck.target, infixCheck.desired);
      });
      break;
    case PROTOTYPE_SERVICES.AXURE:
      AXURE_POSTFIXES.forEach((postfixCheck) => {
        if (!correctedUrl.includes(postfixCheck.target)) correctedUrl = `${correctedUrl}${postfixCheck.desired}`;
        else if (correctedUrl.includes(postfixCheck.override))
          correctedUrl = correctedUrl.replace(postfixCheck.override, postfixCheck.desired);
      });
      break;
    case PROTOTYPE_SERVICES.ADOBEXD:
      if (correctedUrl.includes(ADOBE_INFIX.target))
        correctedUrl = correctedUrl.replace(ADOBE_INFIX.target, ADOBE_INFIX.desired);
      break;
  }
  return correctedUrl;
};

// on desktop, Edge and Chrome hides the navigation with toolbar=0
// and Firefox hides the left-hand-side table of contents (sometimes present normally) with pagemode=none
const transformPdfUrl = (url) => {
  return `${url}#toolbar=0&pagemode=none`;
};

/**
 * If the question has an embed, returns its url and type
 * @param {object} question Current question object
 * @returns {object} { type, url } if question has an embed, and empty object if question is null or has no embed
 */
export const getEmbedFromQuestion = (question) => {
  const conceptUrl = get(question, 'props.properties.conceptUrl');
  if (conceptUrl) {
    const service = getPrototypeServiceFromURL(conceptUrl);
    return { type: EMBED_TYPE.PROTOTYPE, url: serviceToConceptTestUrl(service, conceptUrl) };
  }

  const pdfUrl = get(question, 'props.properties.consentDocument.url');
  if (pdfUrl) return { type: EMBED_TYPE.PDF, url: transformPdfUrl(pdfUrl) };

  return {};
};

export const hasQuestionEmbed = (questions) =>
  questions?.some((question) => {
    return getEmbedFromQuestion(question).url;
  });

export const usePdfEmbedApi = () => {
  const userAgent = parser(window?.navigator?.userAgent);

  // iOS and Android phones don't have a native PDF viewer.
  if (['Android', 'Android-x86', 'iOS'].includes(userAgent.os.name) && userAgent.device.type === 'mobile') return true;

  // latest iPads return Mac OS X useragent, therefore using 'ontouchend' to detect https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  // iPads do have native Safari PDF viewer, but it's very minimal and zoom behaves poorly, so use PDF embed API.
  if (userAgent.device.model === 'iPad' && userAgent.os.name === 'iOS' && 'ontouchend' in window.document) return true;

  // As of May 2022, the latest Windows Firefox browser downloads PDF rather than using a native viewer. https://sprig-inc.atlassian.net/browse/EP-42
  if (userAgent.os.name === 'Windows' && userAgent.browser.name === 'Firefox') return true;

  return false;
};

export const isMobileOrTablet = () => {
  return mobile({ tablet: true });
};

const surveyHasQuestionType = (surveyData, questionType) => {
  return (surveyData?.questions || []).filter((q) => q.type === questionType).length > 0;
};

export const getSurveyValidityError = (surveyData) => {
  if (isMobileOrTablet() && surveyHasQuestionType(surveyData, QUESTION_TYPES.RECORDED_TASK))
    return INVALID_DEVICE_MOBILE_SURVEY;
  return null;
};

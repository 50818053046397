import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import { insertSdkScript, setSdkWindowConfig, unsubscribeEvents } from '../api';
import CenteredLayout from '../components/core/Layouts/CenteredLayout';
import { SURVEY_CLOSED_COPY, SURVEY_FRAME_ID, EMBED_FRAME_ID, FILTER_BLUR_CLASS } from '../lib/constants';
import { hasQuestionEmbed } from '../lib/helpers';
import { useIsMobile, useUrlQueryParams } from '../lib/hooks';
import { internalEvents } from '../lib/sdkEvents';
import Theme from '../lib/Theme';

import ConceptTestSurveyLayout from './core/Layouts/ConceptTestSurveyLayout/ConceptTestSurveyLayout';
import StudyLayout from './core/Layouts/StudyLayout/StudyLayout';
import { SurveyLock } from './questions/SurveyLock';

const Survey = ({
  surveyDefinition,
  isPreview,
  environmentId,
  customMetadata,
  handleSurveyComplete = () => {},
  forceStartFromBeginning = false,
}) => {
  const { border, conceptTesting, lockSurvey, marketingUrl, productName, questions, slugName, surveyId } =
    surveyDefinition;

  const { showQuestionIdx, forceDevice } = useUrlQueryParams();
  // Only allow to display a question index if its in preview mode
  const useDesktopPrototype = forceDevice ? forceDevice === 'desktop' : window.innerWidth >= 1030;
  const questionIdx =
    parseInt(showQuestionIdx) && isPreview && !forceStartFromBeginning ? parseInt(showQuestionIdx) : 0;
  let [currQuestion, setCurrQuestion] = useState(
    questions?.[questionIdx]
      ? {
          qid: questions[questionIdx].name,
          props: questions[questionIdx].props,
          isEmbedViewed: false, // default it is not viewed. only applicable to mobile.
        }
      : null
  );

  let questionLayoutRef = useRef();
  let currQuestionRef = useRef(currQuestion); // use this ref as source of truth. curr question state above is used to re-render the layout if it changes.

  const useMobileStyling = useIsMobile();

  useEffect(() => {
    if (lockSurvey) return;
    document.title = `${productName} research by Sprig`;

    // event name and its callback function to subscribe to
    const eventCallbacks = [
      {
        name: internalEvents.name.CURRENT_QUESTION,
        callbackFn: handleNextQuestion,
      },
      {
        name: internalEvents.name.VIEW_PROTOTYPE_CLICK,
        callbackFn: handleEmbedClick,
      },
      {
        name: internalEvents.name.VIEW_AGREEMENT_CLICK,
        callbackFn: handleEmbedClick,
      },
      {
        name: internalEvents.name.RECORDED_TASK_PERMISSION_SCREEN,
        callbackFn: handleRecordedTaskPermissionScreen,
      },
      {
        name: internalEvents.name.RECORDED_TASK_START,
        callbackFn: handleRecordedTaskStart,
      },
      {
        name: internalEvents.name.SURVEY_COMPLETE,
        callbackFn: handleSurveyComplete,
      },
    ];

    // set up SDK window on websurveys
    setSdkWindowConfig({
      environmentId,
      isPreview,
      survey: surveyDefinition,
      customMetadata,
      useMobileStyling,
      useDesktopPrototype,
      questionIdx,
    });
    insertSdkScript(eventCallbacks);

    // callback function for when component unmount
    return () => {
      unsubscribeEvents(eventCallbacks);
    };
  }, []); // the [] is because of https://css-tricks.com/run-useeffect-only-once/

  const color = border || Theme.colors.darkGrey;

  const introAlert =
    isPreview &&
    !(lockSurvey && [SURVEY_CLOSED_COPY.NOT_FOUND, SURVEY_CLOSED_COPY.NOT_FOUND_PREVIEW].includes(lockSurvey)) &&
    window.top === window.self &&
    'This is a preview; responses will not be recorded.';

  const handleNextQuestion = (payload) => {
    const { qid } = payload;

    // if currQuestion is null, initialize it, otherwise, check for the qid
    if (!currQuestion || currQuestion.qid !== qid) {
      const newQuestion = { ...payload, isEmbedViewed: false };
      currQuestionRef.current = newQuestion;
      setCurrQuestion(newQuestion);
    }
  };

  const handleEmbedClick = (payload) => {
    questionLayoutRef.current();
    setCurrQuestion({ ...payload, isEmbedViewed: true });
  };

  const handleRecordedTaskPermissionScreen = () => {
    const prototypeFrame = document.getElementById(EMBED_FRAME_ID);
    prototypeFrame?.classList.add(FILTER_BLUR_CLASS);
  };

  const handleRecordedTaskStart = () => {
    const prototypeFrame = document.getElementById(EMBED_FRAME_ID);
    prototypeFrame?.classList.remove(FILTER_BLUR_CLASS);
  };

  if (lockSurvey) {
    return (
      <CenteredLayout color={color} introAlert={introAlert} logo={surveyDefinition.logo} productName={productName}>
        <div key="lockSurvey" initial="hide" animate="show" exit="exit">
          <SurveyLock
            color={color}
            marketingUrl={marketingUrl}
            slugName={slugName}
            surveyId={surveyId}
            lockSurvey={lockSurvey}
          />
        </div>
      </CenteredLayout>
    );
  }

  if (!hasQuestionEmbed(questions) && conceptTesting?.[0]?.prototypeUrl) {
    // After question-level concept tests are rolled out to customers, we will deprecate this ConceptTestSurveyLayout and instead use
    // StudyLayout for these studies (with the prototype shown on every question)
    return (
      <ConceptTestSurveyLayout
        color={color}
        conceptTesting={conceptTesting}
        introAlert={introAlert}
        logo={surveyDefinition.logo}
        productName={productName}
        showStripes={surveyDefinition.showStripes}
      >
        <div id={SURVEY_FRAME_ID}></div>
      </ConceptTestSurveyLayout>
    );
  }

  return (
    <StudyLayout
      color={color}
      introAlert={introAlert}
      logo={surveyDefinition.logo}
      productName={productName}
      isEmbedViewed={currQuestion?.isEmbedViewed}
      showStripes={surveyDefinition.showStripes}
      childRef={questionLayoutRef}
      currQuestionRef={currQuestionRef}
    >
      <div id={SURVEY_FRAME_ID}></div>
    </StudyLayout>
  );
};

Survey.propTypes = {
  isPreview: PropTypes.bool,
  surveyDefinition: PropTypes.object,
  environmentId: PropTypes.string,
  customMetadata: PropTypes.object,
  handleSurveyComplete: PropTypes.func,
  forceStartFromBeginning: PropTypes.bool,
};

export default Survey;

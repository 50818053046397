import React from 'react';

import PropTypes from 'prop-types';

const Logo = ({ marketingUrl, slugName, surveyId }) => {
  return (
    <a
      style={{ display: 'block', marginTop: '15px' }}
      href={`${marketingUrl}?utm_source=survey_branding&utm_medium=web_survey&utm_campaign=${slugName}&utm_content=${surveyId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="111"
        height="17"
        viewBox="0 0 111 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M7.06639 5.98519C6.6379 5.69952 5.85882 5.15417 4.24873 5.15417C2.33999 5.15417 1.0675 6.06309 1.0675 7.55632C1.0675 9.08851 2.17119 9.6858 3.75531 10.2182C5.33944 10.7505 5.92374 11.166 5.92374 11.9581C5.92374 12.7632 5.20959 13.1267 4.04097 13.1267C2.63864 13.1267 1.80762 12.4775 1.45704 12.2178L0.898697 13.4513C1.39211 13.8149 2.27507 14.4252 4.05396 14.4252C6.19642 14.4252 7.39101 13.3864 7.39101 11.8023C7.39101 10.361 6.41716 9.60789 4.7811 9.07552C3.14504 8.55614 2.49581 8.29645 2.49581 7.46543C2.49581 6.75128 3.19697 6.42666 4.26171 6.42666C5.43033 6.42666 6.18344 6.93306 6.53402 7.1538L7.06639 5.98519Z"
          fill="#9F9EAF"
        />
        <path
          d="M14.3174 7.90691H12.915V12.4775C12.6683 12.7502 12.2009 13.1787 11.4088 13.1787C10.461 13.1787 10.0325 12.7502 10.0325 11.5556V7.90691H8.63012V11.8412C8.63012 13.6591 9.50009 14.4252 10.9154 14.4252C12.2398 14.4252 12.7333 13.8409 12.954 13.5812H12.98V14.2694H14.3174V7.90691Z"
          fill="#9F9EAF"
        />
        <path
          d="M19.3109 7.79005C17.9605 7.79005 17.506 8.3354 17.2593 8.89374H17.2333V7.90691H15.8959V14.2694H17.2983V10.0234C17.5839 9.45208 18.1163 9.0106 19.0122 9.0106C19.1291 9.0106 19.233 9.0106 19.3109 9.02359V7.79005Z"
          fill="#9F9EAF"
        />
        <path
          d="M25.983 7.90691H24.5157L22.8407 12.4126H22.8147L21.1397 7.90691H19.6205L22.2304 14.2694H23.373L25.983 7.90691Z"
          fill="#9F9EAF"
        />
        <path
          d="M26.2979 11.0752C26.2979 13.3864 27.5574 14.4252 29.3623 14.4252C30.8815 14.4252 31.6476 13.8409 31.9462 13.5552L31.4398 12.5554C31.2451 12.7242 30.6348 13.2046 29.609 13.2046C28.4534 13.2046 27.8301 12.6333 27.7133 11.4907H31.9592V10.8414C31.9592 8.76389 30.9984 7.75109 29.2974 7.75109C27.6224 7.75109 26.2979 8.90672 26.2979 11.0752ZM29.2714 8.93269C30.1673 8.93269 30.6348 9.49103 30.6478 10.4649H27.7003C27.7912 9.45208 28.4144 8.93269 29.2714 8.93269Z"
          fill="#9F9EAF"
        />
        <path
          d="M38.6638 7.90691H37.1966L35.5865 12.4126H35.5605L33.8336 7.90691H32.3014L34.8594 13.9837C34.4049 15.1523 33.8725 15.6847 32.4312 15.6847V16.9442C34.6906 16.9442 35.3138 16.243 36.3136 13.776L38.6638 7.90691Z"
          fill="#9F9EAF"
        />
        <path
          d="M48.9289 10.9713C48.9289 8.81583 47.8252 7.75109 46.319 7.75109C45.1893 7.75109 44.644 8.20556 44.4103 8.43928H44.3843V5.05029H42.9819V14.2694H44.3194V13.5812H44.3453C44.5531 13.8798 45.0075 14.4252 46.1891 14.4252C47.7863 14.4252 48.9289 13.2046 48.9289 10.9713ZM47.5006 11.0622C47.5006 12.5035 46.8124 13.1787 45.8386 13.1787C45.0595 13.1787 44.618 12.7761 44.3843 12.4775V9.67282C44.605 9.38716 45.0984 8.97165 45.8905 8.97165C46.8773 8.97165 47.5006 9.64685 47.5006 11.0622Z"
          fill="#9F9EAF"
        />
        <path
          d="M55.7188 7.90691H54.2516L52.6415 12.4126H52.6155L50.8886 7.90691H49.3564L51.9143 13.9837C51.4599 15.1523 50.9275 15.6847 49.4862 15.6847V16.9442C51.7455 16.9442 52.3688 16.243 53.3686 13.776L55.7188 7.90691Z"
          fill="#9F9EAF"
        />
        <path
          d="M67.1977 5.31519H65.8029V10.2486C65.8029 12.1885 65.0151 13.0236 63.4396 13.0236C61.8382 13.0236 61.0504 12.1757 61.0504 10.2486V5.31519H59.6169V10.2229C59.6169 13.0107 60.7921 14.334 63.375 14.334C65.9837 14.334 67.1977 12.9208 67.1977 10.2229V5.31519Z"
          fill="#9F9EAF"
        />
        <path
          d="M72.8715 8.33431C72.5357 8.11591 71.9029 7.73049 70.7277 7.73049C69.3458 7.73049 68.416 8.3857 68.416 9.50342C68.416 10.6083 69.1263 11.0579 70.2886 11.4562C71.4509 11.8416 71.6704 12.0729 71.6704 12.4712C71.6704 12.8694 71.3476 13.1521 70.5856 13.1521C69.6558 13.1521 69.0617 12.7281 68.7776 12.484L68.2997 13.6274C68.6226 13.8844 69.2942 14.334 70.6243 14.334C72.1999 14.334 73.0393 13.5503 73.0393 12.3684C73.0393 11.3406 72.3549 10.8138 71.1797 10.4413C69.9786 10.0559 69.7332 9.8503 69.7332 9.45203C69.7332 9.09231 70.0819 8.8739 70.7535 8.8739C71.5542 8.8739 72.0966 9.20793 72.4194 9.40064L72.8715 8.33431Z"
          fill="#9F9EAF"
        />
        <path
          d="M73.9249 11.0194C73.9249 13.3062 75.1776 14.334 76.9727 14.334C78.4837 14.334 79.2457 13.7559 79.5427 13.4732L79.039 12.484C78.8453 12.651 78.2383 13.1264 77.2181 13.1264C76.0687 13.1264 75.4488 12.5611 75.3326 11.4305H79.5556V10.7882C79.5556 8.73258 78.5999 7.73049 76.9081 7.73049C75.2422 7.73049 73.9249 8.8739 73.9249 11.0194ZM76.8823 8.8996C77.7734 8.8996 78.2383 9.45203 78.2512 10.4156H75.3197C75.4101 9.41349 76.03 8.8996 76.8823 8.8996Z"
          fill="#9F9EAF"
        />
        <path
          d="M84.1907 7.76903C82.8476 7.76903 82.3956 8.30862 82.1502 8.86105H82.1244V7.88466H80.7942V14.1798H82.1889V9.97877C82.4731 9.41349 83.0026 8.97668 83.8937 8.97668C84.0099 8.97668 84.1132 8.97668 84.1907 8.98953V7.76903Z"
          fill="#9F9EAF"
        />
        <path d="M86.7283 5.31519H85.3078V14.1798H90.4499V12.8951H86.7283V5.31519Z" fill="#9F9EAF" />
        <path
          d="M91.2013 11.0194C91.2013 13.3062 92.454 14.334 94.2491 14.334C95.7601 14.334 96.522 13.7559 96.819 13.4732L96.3153 12.484C96.1216 12.651 95.5147 13.1264 94.4944 13.1264C93.3451 13.1264 92.7252 12.5611 92.6089 11.4305H96.8319V10.7881C96.8319 8.73258 95.8763 7.73048 94.1845 7.73048C92.5185 7.73048 91.2013 8.8739 91.2013 11.0194ZM94.1587 8.89959C95.0498 8.89959 95.5146 9.45203 95.5275 10.4156H92.596C92.6864 9.41348 93.3063 8.89959 94.1587 8.89959Z"
          fill="#9F9EAF"
        />
        <path
          d="M97.9238 12.4455C97.9238 13.5889 98.5953 14.334 99.8997 14.334C101.178 14.334 101.643 13.743 101.824 13.5118H101.837V14.1798H103.154V10.4413C103.154 8.71973 102.392 7.73048 100.61 7.73048C99.1636 7.73048 98.3629 8.36 98.0013 8.74542L98.5695 9.65758C98.8665 9.36209 99.4348 8.91244 100.416 8.91244C101.372 8.91244 101.772 9.38779 101.772 10.4027V10.544C101.553 10.5184 101.191 10.4927 100.726 10.4927C98.9828 10.4927 97.9238 11.1607 97.9238 12.4455ZM99.3056 12.3427C99.3056 11.7774 99.7964 11.4819 100.842 11.4819C101.256 11.4819 101.54 11.4948 101.772 11.5076V12.5611C101.604 12.7538 101.191 13.1906 100.287 13.1906C99.6285 13.1906 99.3056 12.8823 99.3056 12.3427Z"
          fill="#9F9EAF"
        />
        <path
          d="M110.473 10.9166C110.473 8.8482 109.401 7.73048 107.903 7.73048C106.702 7.73048 106.134 8.28292 105.902 8.56556H105.876V7.88465H104.546V16.7493H105.94V13.6659H105.966C106.186 13.8972 106.599 14.334 107.697 14.334C109.272 14.334 110.473 13.2034 110.473 10.9166ZM109.04 10.9937C109.04 12.4455 108.407 13.1007 107.374 13.1007C106.56 13.1007 106.147 12.6767 105.94 12.3941V9.64474C106.16 9.36209 106.638 8.93813 107.426 8.93813C108.433 8.93813 109.04 9.64474 109.04 10.9937Z"
          fill="#9F9EAF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.0043 2.1502C85.9592 1.32706 81.7562 1.31311 77.1279 4.64932C76.8643 4.83933 76.4958 4.78078 76.3048 4.51857C76.1138 4.25635 76.1727 3.88975 76.4363 3.69974C81.39 0.128891 85.9705 0.114946 89.3134 1.01857C90.9751 1.46775 92.3232 2.14071 93.2564 2.7013C93.7236 2.98195 94.0886 3.23552 94.339 3.42074C94.4642 3.5134 94.561 3.58907 94.6276 3.64263C94.6609 3.66942 94.6867 3.69069 94.7048 3.70581C94.7139 3.71337 94.721 3.71939 94.7262 3.72379L94.7325 3.72918L94.7345 3.73094L94.7353 3.73159C94.7356 3.73184 94.7358 3.73207 94.3491 4.17453L94.7358 3.73207C94.9815 3.94455 95.0075 4.3149 94.7939 4.55926C94.5804 4.80347 94.2085 4.82944 93.9628 4.61738C93.9628 4.61736 93.9628 4.6174 93.9628 4.61738L93.9624 4.61699L93.9604 4.61531L93.947 4.60405C93.9343 4.59345 93.9141 4.57678 93.8866 4.55466C93.8316 4.51042 93.7474 4.44445 93.6356 4.36172C93.4118 4.1962 93.0782 3.96412 92.6471 3.70515C91.7837 3.18651 90.5366 2.5644 89.0043 2.1502Z"
          fill="#9F9EAF"
        />
      </svg>
    </a>
  );
};

Logo.propTypes = {
  marketingUrl: PropTypes.string,
  slugName: PropTypes.string,
  surveyId: PropTypes.number,
};

export default Logo;

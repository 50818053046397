import { datadogRum } from '@datadog/browser-rum';

import { getAppEnvironment, getWebSurveyUrl } from './sentry';

export const initDDRUM = () => {
  const env = getAppEnvironment();
  const web = getWebSurveyUrl();
  datadogRum.init({
    applicationId: '7bff4de0-312f-4d45-8d7b-de66185acc75',
    clientToken: 'pubc36cff30c0ddeca9044092b56c6c4c37',
    site: 'datadoghq.com',
    service: 'sprig-websurveys',
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: web,
  });
};

initDDRUM();

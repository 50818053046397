import React, { useState, useEffect } from 'react';

import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getEmailSurvey, getLinkSurvey, getEmailSurveyForTestUnsubscribe } from '../api';
import { NOT_FOUND_SURVEY } from '../lib/constants';
import { getQueryParams, parseSurveyToken } from '../lib/helpers';
import Theme from '../lib/Theme';

import CenteredLayout from './core/Layouts/CenteredLayout';
import Spinner from './core/Spinner';
import Resubscribe from './Resubscribe';
import Survey from './Survey';
import Unsubscribe from './Unsubscribe';

export const VIEW_TYPE = {
  DEFAULT: 'default',
  UNSUBSCRIBE: 'unsubscribe',
  RESUBSCRIBE: 'resubscribe',
  TEST_UNSUBSCRIBE: 'testUnsubscribe',
};

const SurveyLoader = ({ viewType }) => {
  const [survey, setSurvey] = useState();
  const { surveyToken } = useParams();
  const { customMetadata, firstAnswer } = getQueryParams();
  const { environmentId, surveyId, emailSurveyId } = parseSurveyToken(surveyToken);

  useEffect(() => {
    if (survey) {
      return;
    }

    if (!(environmentId && (surveyId || emailSurveyId))) {
      Sentry.withScope(function (scope) {
        scope.setTag('surveyToken', surveyToken);
        scope.setTag('environmentId', environmentId);
        scope.setTag('surveyId', surveyId);
        scope.setTag('emailSurveyId', emailSurveyId);
        Sentry.captureException(new Error('Unable to parse survey token; may be an improperly-entered URL.'));
      });
      setSurvey(NOT_FOUND_SURVEY);
      return;
    }

    if (surveyId) {
      getLinkSurvey(environmentId, surveyId, firstAnswer, customMetadata).then((response) => {
        setSurvey({ ...response });
      });
    } else {
      if (viewType === VIEW_TYPE.TEST_UNSUBSCRIBE) {
        const surveyUuid = emailSurveyId;
        getEmailSurveyForTestUnsubscribe(environmentId, surveyUuid).then((response) => {
          setSurvey({ ...response });
        });
      } else {
        getEmailSurvey(environmentId, emailSurveyId, firstAnswer).then((response) => {
          setSurvey({ ...response });
        });
      }
    }
  }, [survey]);

  if (!survey) {
    return (
      <CenteredLayout color={Theme.colors.darkGrey}>
        <LoadingContainer>
          <StyledLoadingText>Loading...</StyledLoadingText>
          <Spinner color={Theme.colors.darkGrey} />
        </LoadingContainer>
      </CenteredLayout>
    );
  }

  switch (viewType) {
    case VIEW_TYPE.DEFAULT:
      return (
        <Survey
          surveyDefinition={survey}
          environmentId={environmentId}
          isPreview={false}
          customMetadata={customMetadata}
        />
      );
    case VIEW_TYPE.UNSUBSCRIBE:
      return <Unsubscribe surveyDefinition={survey} environmentId={environmentId} />;
    case VIEW_TYPE.TEST_UNSUBSCRIBE:
      return <Unsubscribe surveyDefinition={survey} environmentId={environmentId} testMode={true} />;
    case VIEW_TYPE.RESUBSCRIBE:
      return <Resubscribe surveyDefinition={survey} environmentId={environmentId} />;
    default:
      console.error(`Invalid viewType ${viewType}; cannot view survey.`);
      break;
  }
};

SurveyLoader.propTypes = {
  viewType: PropTypes.oneOf([
    VIEW_TYPE.DEFAULT,
    VIEW_TYPE.UNSUBSCRIBE,
    VIEW_TYPE.RESUBSCRIBE,
    VIEW_TYPE.TEST_UNSUBSCRIBE,
  ]),
};

const StyledLoadingText = styled('h5')`
  font-size: 1.5em;
  margin: ${Theme.spacing.xl} 0;
  color: ${Theme.colors.offBlack};
`;

const LoadingContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 2.5em;
  margin: 0px -2.5em;
`;

export default SurveyLoader;

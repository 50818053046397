import React from 'react';

import PropTypes from 'prop-types';

const SurveyNotFoundSvg = (props) => (
  <svg viewBox="0 0 204 160" fill="none" className={props.className}>
    <path d="M102 2L102 61.64" stroke="#E6E2FB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M60.8573 103L144.193 103L204 160H0L60.8573 103Z" fill="url(#paint0_linear)" />
    <path
      d="M97.1851 69.7244C100.216 67.0301 104.784 67.0301 107.815 69.7244L143 101H62L97.1851 69.7244Z"
      fill="#543EDB"
    />
    <path
      d="M108 65C108 68.3137 105.314 71 102 71C98.6863 71 96 68.3137 96 65C96 61.6863 98.6863 59 102 59C105.314 59 108 61.6863 108 65Z"
      fill="#543EDB"
      stroke="white"
      strokeWidth="2"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="117.509" y1="103" x2="117.509" y2="160" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E6E2FB" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

SurveyNotFoundSvg.propTypes = {
  className: PropTypes.string,
};

export default SurveyNotFoundSvg;

import * as Sentry from '@sentry/browser';

import { environment } from '@sprigShared/environment';

export const getAppEnvironment = () => environment.environment;

export const getWebSurveyUrl = () => environment.webSurveyUrl;

export const initSentry = () => {
  if (environment.isLocal()) {
    console.log('Local environment does not have Sentry configured.');
    return;
  }

  Sentry.init({
    dsn: 'https://201e9dc0d9814f9daf21232db4b0859a@o167289.ingest.sentry.io/1397953',
    environment: getAppEnvironment(),
  });
};

initSentry();

// Ensure sentry & datadog are the first pieces of code to be executed
/* eslint-disable import/order */
import './lib/datadog';
import './lib/sentry';
/* eslint-enable import/order */

import React from 'react';

import 'promise-polyfill/src/polyfill';
import { createRoot } from 'react-dom/client';

import App from './App';

import './index.css';

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);
root.render(<App />);

const Theme = {
  borderRadius: '4px',
  colors: {
    black: 'hsla(248, 44%, 15%, 1)',
    offBlack: '#343442',
    primary: '#543edb',
    grey: '#F0F0F1',
    darkGrey: '#666',
    white: '#FFFFFF',
  },
  spacing: {
    none: '0',
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    xxl: '3rem',
  },
  timing: {
    fast: '100ms',
    normal: '200ms',
    slow: '300ms',
  },
};

export default Theme;

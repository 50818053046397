import React from 'react';

const RightArrowCircleSvg = () => (
  <svg width="19" height="16" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 7L11.5 10L8.5 13" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect
      x="0.75"
      y="18.75"
      width="17.5"
      height="17.5"
      rx="8.75"
      transform="rotate(-90 0.75 18.75)"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);

export default RightArrowCircleSvg;

import Color from 'color';

export const lightColor = (color) => Color(color).lightness(80).string();

export const lighterColor = (color) => Color(color).lightness(97).string();

export const backgroundColorForText = (colorStr) => {
  const color = Color(colorStr);
  const luminosity = color.luminosity();
  const isNonCommittal = luminosity > 0.45 && luminosity < 0.55;
  return isNonCommittal ? color.lightness(40).string() : colorStr;
};

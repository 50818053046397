import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import SurveyLoader, { VIEW_TYPE } from './components/SurveyLoader';
import SurveyReview, { REVIEW_TYPE } from './components/SurveyReview';

// ADDING COMMENT TO FORCE DEPLOY
class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/:surveyToken/unsubscribe">
            <SurveyLoader viewType={VIEW_TYPE.UNSUBSCRIBE} />
          </Route>
          <Route path="/:surveyToken/testUnsubscribe">
            <SurveyLoader viewType={VIEW_TYPE.TEST_UNSUBSCRIBE} />
          </Route>
          <Route path="/:surveyToken/resubscribe">
            <SurveyLoader viewType={VIEW_TYPE.RESUBSCRIBE} />
          </Route>
          <Route exact path="/:surveyToken">
            <SurveyLoader viewType={VIEW_TYPE.DEFAULT} />
          </Route>
          <Route exact path="/:environmentId/preview/:platform/:templateId">
            <SurveyReview reviewType={REVIEW_TYPE.TEMPLATE} />
          </Route>
          <Route exact path="/:environmentId/review/:surveyId">
            <SurveyReview reviewType={REVIEW_TYPE.SURVEY} />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
